import type { PhotoRequirement, ClaimPhotoDetail } from '../types/claim'

export interface CurrentRequirementState {
  isLastRequirement: boolean
  requirement: PhotoRequirement
  index: number
}

export const getInitialPhotoRequirementsState = (photoRequirements: PhotoRequirement[], claimPhotos: ClaimPhotoDetail[]) => {
  if (!photoRequirements.length) return null
  // if there are no claim photos, we can assume that we need to show the first requirement
  if (!claimPhotos.length)
    return {
      isLastRequirement: photoRequirements.length === 1,
      requirement: photoRequirements[0],
      index: 0,
    }

  const uploadedPhotos = claimPhotos.filter((photo) => !['deleted', 'rejected'].includes(photo.status))
  const currentRequirementIndex = photoRequirements.findIndex(
    (requirement) => !uploadedPhotos.some((photo) => photo?.requirement?.id === requirement.id),
  )

  if (currentRequirementIndex === -1) return null

  return {
    isLastRequirement: currentRequirementIndex === photoRequirements.length - 1,
    requirement: photoRequirements[currentRequirementIndex],
    index: currentRequirementIndex,
  }
}
