import { useState, useRef, useEffect } from 'react'
import { useCreateClaimPhotoMutation, useUploadClaimPhotoMutation } from '@customers-api-rtk-query'
import type { PhotoRequirement } from '../../../types/claim'

export interface UsePhotoRequirementsUploadProps {
  claimId: string
  currentRequirement: PhotoRequirement | null
  onError: () => void
}

/**
 * Hook to handle photo upload flow
 * Manages both creating photo records and uploading files
 */
export function usePhotoRequirementsUpload({ claimId, currentRequirement, onError }: UsePhotoRequirementsUploadProps) {
  const [currentImage, setCurrentImage] = useState<File>()
  const [photoId, setPhotoId] = useState<string | null>(null)
  const isMountedRef = useRef(true)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  const [createPhoto, { isLoading: isCreatePhotoLoading }] = useCreateClaimPhotoMutation()
  const [uploadPhoto, { isLoading: isUploadPhotoLoading }] = useUploadClaimPhotoMutation()

  const handleUpload = async () => {
    if (!currentImage || !currentRequirement || !isMountedRef.current) return

    try {
      const createClaimPhotoResponse = await createPhoto({
        claimId,
        body: {
          source: 'chatbot',
          description: currentRequirement.description as string,
          requirement: currentRequirement,
        },
      }).unwrap()

      if (!isMountedRef.current) return

      const { photoId, ...presignedPost } = createClaimPhotoResponse
      const { url, fields } = presignedPost

      const formData = new FormData()
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value as string)
      })
      formData.append('file', currentImage)

      try {
        await uploadPhoto({ url, body: formData }).unwrap()
        if (!isMountedRef.current) return
        setCurrentImage(undefined)
        setPhotoId(photoId)
      } catch (err: unknown) {
        if (!isMountedRef.current) return
        // There can be CORS errors despite successfully uploading
        if (
          (err as { status: string; error: string }).status === 'FETCH_ERROR' ||
          (err as { status: string; error: string }).error ===
            'TypeError: Failed to fetch'
        ) {
          setCurrentImage(undefined)
          setPhotoId(photoId)
          return
        }
        onError()
        throw err
      }
    } catch (err) {
      onError()
      throw err
    }
  }

  return {
    photoId,
    currentImage,
    setCurrentImage,
    handleUpload,
    isLoading: isCreatePhotoLoading || isUploadPhotoLoading,
  }
}
