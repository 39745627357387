import type { FC } from 'react'
import React from 'react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import images from '../../pngs'
import { bp } from '../../utils/breakpoints'

interface LinkText {
  displayText: string
}

interface LinkPropsOnClick extends LinkText {
  handleOnClick: () => void
}

interface LinkPropsHref extends LinkText {
  href: string
}

export type LinkProps = LinkPropsOnClick | LinkPropsHref | LinkText

export interface FooterProps {
  className?: string
  links?: LinkProps[]
}

const Footer: FC<FooterProps> = ({ className, links }) => {
  const theme = useTheme()
  const { hideExtendBranding } = theme.merchantConfiguration

  const linksRender = links?.map((link: LinkProps) => {
    if ('handleOnClick' in link) {
      return (
        <LinkButton key={link.displayText} onClick={link.handleOnClick}>
          {link.displayText}
        </LinkButton>
      )
    }

    if ('href' in link) {
      return (
        <Link key={link.displayText} href={link.href} target="_blank" rel="noopener noreferrer">
          {link.displayText}
        </Link>
      )
    }

    return <FooterText key={link.displayText}>{link.displayText}</FooterText>
  })

  return (
    <FooterWrapper className={className} role="main">
      <FooterContainer>
        {!hideExtendBranding && (
          <FooterImage>
            <img src={images.poweredByExtend} alt="PoweredByExtend" />
          </FooterImage>
        )}
        {!links && (
          <LinksSection>
            <Link href="https://www.extend.com/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </Link>
            <Link href="https://www.extend.com/terms" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </Link>
          </LinksSection>
        )}
        {links && <LinksSection>{linksRender}</LinksSection>}
      </FooterContainer>
    </FooterWrapper>
  )
}

const FooterText = styled.div({
  textAlign: 'center',
  color: COLOR.NEUTRAL[1000],
  font: 'normal',
  fontWeight: 300,
  fontSize: 14,
  lineHeight: '22px',
})

const FooterImage = styled.div({
  width: '144px',
  height: '16px',
  display: 'flex',
  alignItems: 'center',
})

const Link = styled.a({
  color: COLOR.NEUTRAL[1000],
  font: 'normal',
  fontWeight: 300,
  fontSize: 14,
  lineHeight: '22px',
  '&:hover': { textDecoration: 'underline' },
})

const LinkButton = styled.button({
  textAlign: 'left',
  color: COLOR.NEUTRAL[1000],
  font: 'normal',
  fontWeight: 300,
  fontSize: 14,
  lineHeight: '22px',
  border: 'none',
  background: COLOR.WHITE,
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
})

const LinksSection = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 32,
  [bp.mobile]: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  [bp.desktop]: {
    marginLeft: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const FooterContainer = styled.div({
  maxWidth: '1128px',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [bp.mobile]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 16,
    padding: '16px 20px 16px 20px',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const FooterWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  background: COLOR.WHITE,
  margin: 0,
  borderTop: `1px solid ${COLOR.NEUTRAL.OPACITY[50]}`,
  width: '100vw',
  bottom: 0,
  padding: '0 16px',
  boxSizing: 'border-box',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [bp.mobile]: {
    height: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [bp.desktop]: {
    height: '64px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export { Footer }
