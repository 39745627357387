import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import type { FC } from 'react'
import type { Address, ClaimStatus } from '@customers-api-client'
import { Button, COLOR } from '@extend/zen'
import { formatPhoneNumberIso } from '@extend/client-helpers'
import { isMobile } from 'react-device-detect'
import { bp } from '@customers-ui'
import { useUpdateClaimMutation } from '@customers-api-rtk-query'
import type { Claim, ClaimAddress } from '../../types/claim'
import { formatAddress } from '../../utils/format-address'
import { logEvent } from '../../analytics'
import { hasUnmetPhotoRequirements } from '../../lib/helper-functions'
import * as selectors from '../../reducers/selectors'
import { useSelector } from 'react-redux'
import { getAccountInfo, getShippingAddress } from '../../lib/consumer-profile-utils'
import { useHistory } from 'react-router-dom'
import { AddressInput } from '../../components/common/address-input'

interface CustomerShippingAddressProps {
  claim: Claim
  handleModalOpen: () => void
  submitClaim: ({ claimId }: { claimId: string }) => void
  isClaimSubmitSuccess?: boolean
  isMissingRequiredProfileField: boolean
}

const CustomerShippingAddress: FC<CustomerShippingAddressProps> = ({
  claim,
  handleModalOpen,
  submitClaim,
  isClaimSubmitSuccess,
  isMissingRequiredProfileField,
}) => {
  const history = useHistory()
  const consumerProfile = useSelector(selectors.getConsumerProfile)

  const { phoneNumber, email, name } = getAccountInfo(consumerProfile)

  const shippingAddress = claim?.customer?.shippingAddress
    ? claim.customer.shippingAddress
    : consumerProfile && getShippingAddress(consumerProfile)

  const customerName = name || claim?.customer?.name

  const isCompleteConsumerShippingAddress = Boolean(
    shippingAddress?.address1 &&
      shippingAddress?.city &&
      shippingAddress?.countryCode &&
      shippingAddress?.provinceCode &&
      shippingAddress?.postalCode,
  )

  const [isUserEditingAddress, setIsUserEditingAddress] = useState<boolean>(!isCompleteConsumerShippingAddress)
  const [isClaimSubmitted, setIsClaimSubmitted] = useState<boolean>(
    (claim.status as ClaimStatus) !== 'pending_adjudication',
  )
  const [shouldDisplaySubmitButtons, setShouldDisplaySubmitButtons] = useState<boolean>(!isClaimSubmitted)
  const [customerAddress, setCustomerAddress] = useState<ClaimAddress>()

  const [
    updateClaim,
    { isSuccess: isClaimUpdateSuccess, isError: isClaimUpdateError, isLoading: isClaimUpdateLoading },
  ] = useUpdateClaimMutation()

  const isPendingShippingClaim =
    !isClaimSubmitted && ['shipping_resolution', 'shipping_protection'].includes(claim.type)
  useEffect(() => {
    if (isPendingShippingClaim) {
      setIsUserEditingAddress(true)
    }
  }, [isPendingShippingClaim])

  useEffect(() => {
    if (isClaimUpdateSuccess) {
      setIsUserEditingAddress(false)
    }

    if (isClaimSubmitSuccess) {
      setIsClaimSubmitted(true)
    }

    if (isClaimUpdateError) {
      history.push('/error')
    }
  }, [
    isClaimUpdateSuccess,
    setIsUserEditingAddress,
    isClaimSubmitSuccess,
    isClaimSubmitted,
    isClaimUpdateError,
    history,
  ])

  const handleAddressChange = (address: ClaimAddress): void => {
    setCustomerAddress(address)
  }

  const handleUpdateAddress = (): void => {
    setIsUserEditingAddress(true)
  }

  const handleCancelAddressInput = (): void => {
    setIsUserEditingAddress(false)
  }

  const handleUpdateClaimAddress = async (address?: ClaimAddress): Promise<void> => {
    if (!shippingAddress && !address) return
    const existingAddress = { ...shippingAddress }
    delete existingAddress.isPrimary
    delete existingAddress.type
    await updateClaim({
      claimId: claim.id,
      body: {
        customer: {
          ...claim.customer,
          name: customerName,
          email: email ?? '',
          phone: phoneNumber ? formatPhoneNumberIso(phoneNumber) : '',
          shippingAddress: address || existingAddress,
        },
      },
    }).unwrap()
  }

  const handleSaveAndSubmit = async (): Promise<void> => {
    if (!customerAddress) return
    logEvent('Review Claim - Update Address - Click', 'Save and submit claim')
    await handleUpdateClaimAddress(customerAddress)
    await handleSubmitClaim(true)
  }

  const handleSubmitClaim = async (wasAddressUpdated = false): Promise<void> => {
    logEvent('Review Claim - Click', 'Yes, submit claim')
    setShouldDisplaySubmitButtons(false)
    handleModalOpen()

    // prevent updating the claim address twice
    if (!wasAddressUpdated) {
      await handleUpdateClaimAddress()
    }

    logEvent('Review Claim - Submit Claim - Click', 'Address verified & claim submitted')

    if (!hasUnmetPhotoRequirements(claim)) {
      submitClaim({ claimId: claim.id })
    }
  }

  return (
    <ShippingAddressWrapper data-cy="shipping-address-wrapper">
      <InfoWrapper>
        {!isClaimSubmitted && !isUserEditingAddress && (
          <AddressVerificationQuestion data-cy="address-verification-question">
            Is this address up to date?
          </AddressVerificationQuestion>
        )}
        {!isUserEditingAddress && (
          <>
            <Header data-cy="shipping-address-header">Shipping Address</Header>
            <ShippingAddressString data-cy="shipping-address-string">
              {formatAddress(customerAddress ?? (shippingAddress as Address))}
            </ShippingAddressString>
          </>
        )}
        {isUserEditingAddress && <AddressInput onAddressChange={handleAddressChange} />}
      </InfoWrapper>
      {!isClaimSubmitted && (
        <ButtonContainer>
          {!isUserEditingAddress && shouldDisplaySubmitButtons && (
            <>
              <Button
                emphasis="medium"
                text="Yes, submit claim"
                fillContainer={isMobile}
                onClick={() => handleSubmitClaim()}
                data-cy="submit-claim-button"
                isDisabled={isMissingRequiredProfileField}
                color="neutral"
                isProcessing={isClaimUpdateLoading}
              />
              <Button
                emphasis="medium"
                text="No, update address"
                onClick={handleUpdateAddress}
                fillContainer={isMobile}
                data-cy="update-address-button"
                isDisabled={isMissingRequiredProfileField}
                color="neutral"
              />
            </>
          )}
          {isUserEditingAddress && (
            <>
              <Button
                emphasis="high"
                text="Save and submit claim"
                fillContainer={isMobile}
                onClick={handleSaveAndSubmit}
                data-cy="save-and-submit-button"
                isDisabled={isMissingRequiredProfileField}
                color="neutral"
                isProcessing={isClaimUpdateLoading}
              />
              {!['shipping_resolution', 'shipping_protection'].includes(claim.type) && (
                <Button
                  emphasis="medium"
                  text="Cancel"
                  isDisabled={!isCompleteConsumerShippingAddress || isMissingRequiredProfileField}
                  onClick={handleCancelAddressInput}
                  fillContainer={isMobile}
                  data-cy="cancel-button"
                  color="neutral"
                />
              )}
            </>
          )}
        </ButtonContainer>
      )}
    </ShippingAddressWrapper>
  )
}

const ShippingAddressWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const InfoWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
})

const AddressVerificationQuestion = styled.div({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  color: COLOR.BLACK,
})

const Header = styled.div({
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  color: COLOR.NEUTRAL[600],
})

const ShippingAddressString = styled.div({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: COLOR.NEUTRAL[1000],
})

const ButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  alignSelf: 'stretch',
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

export { CustomerShippingAddress }
