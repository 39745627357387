import type { DecodedAccessToken } from '@customers-api-client'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { ConsumerProfileResponse } from '@customers-api-rtk-query'
import { decodeToken, isValidToken } from '../../lib/security-utils/jwt'
import { ACCESS_TOKEN_KEY, getItem, setItem } from '../persistence'

interface MyExtendState {
  accessToken: string | null
  emailToken: string | null
  phoneToken: string | null
  isLoggedIn: boolean
  decodedAccessToken: DecodedAccessToken | null
  profile: ConsumerProfileResponse | null
  isFirstTimeLogin: boolean | null
}

const initialState: MyExtendState = {
  accessToken: null,
  emailToken: null,
  phoneToken: null,
  isLoggedIn: false,
  decodedAccessToken: null,
  profile: null,
  isFirstTimeLogin: null,
}

const myExtendSlice = createSlice({
  name: 'myExtend',
  initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<string>) {
      setItem(ACCESS_TOKEN_KEY, action.payload)
      return {
        ...state,
        accessToken: action.payload,
        isLoggedIn: isValidToken(action.payload),
        decodedAccessToken: decodeToken(action.payload),
      }
    },
    setEmailToken(state, action: PayloadAction<string>) {
      return {
        ...state,
        emailToken: action.payload,
      }
    },
    setPhoneToken(state, action: PayloadAction<string>) {
      return {
        ...state,
        phoneToken: action.payload,
      }
    },
    setConsumerProfile(state, action: PayloadAction<ConsumerProfileResponse>) {
      setItem('profile', JSON.stringify(action.payload))
      return {
        ...state,
        profile: action.payload,
      }
    },
    setIsFirstTimeLogin(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isFirstTimeLogin: action.payload,
      }
    },
    setLogoutUser() {
      setItem('profile', null)
      setItem(ACCESS_TOKEN_KEY, null)

      return initialState
    },
  },
})

const getAccessToken = (state: MyExtendState): string | null => {
  if (state.accessToken) {
    return state.accessToken
  }
  return getItem(ACCESS_TOKEN_KEY)
}

const getEmailToken = (state: MyExtendState): string | null => {
  return state.emailToken
}

const getPhoneToken = (state: MyExtendState): string | null => {
  return state.phoneToken
}

const getConsumerProfile = (state: MyExtendState): ConsumerProfileResponse | null => {
  if (state.profile) {
    return state.profile
  }

  return typeof getItem('profile') === 'string' ? JSON.parse(getItem('profile') as string) : {}
}

const getIsFirstTimeLogin = (state: MyExtendState): boolean | null => {
  return state.isFirstTimeLogin
}

const isLoggedIn = (state: MyExtendState): boolean => {
  const accessToken = getAccessToken(state)

  return isValidToken(accessToken)
}

const getDecodedAccessToken = (state: MyExtendState): DecodedAccessToken | null => {
  const accessToken = getAccessToken(state)
  if (!accessToken) {
    return null
  }
  return decodeToken(accessToken)
}

const myExtendReducer = myExtendSlice.reducer
const { setAccessToken, setConsumerProfile, setLogoutUser, setEmailToken, setPhoneToken, setIsFirstTimeLogin } =
  myExtendSlice.actions

export type { MyExtendState }
export {
  myExtendReducer,
  setAccessToken,
  getAccessToken,
  getEmailToken,
  getPhoneToken,
  isLoggedIn,
  getDecodedAccessToken,
  getConsumerProfile,
  setConsumerProfile,
  setLogoutUser,
  setEmailToken,
  setPhoneToken,
  setIsFirstTimeLogin,
  getIsFirstTimeLogin,
}
