import type { FC } from 'react'
import React, { useState } from 'react'
import { Modal, ToastColor, ToastDuration, useToaster, Loading } from '@extend/zen'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useUpdateConsumerProfileMutation } from '@customers-api-rtk-query'
import { MyExtendAuthForm } from '../../myextend-authentication/myextend-auth-form'
import * as selectors from '../../../reducers/selectors'
import { setConsumerProfile } from '../../../store/slices/my-extend'
import { PopaFlow } from '../../../components/popa-flow'
import { formatPhoneNumberIso } from '@extend/client-helpers'

interface VerificationModalProps {
  isEmail?: boolean
  userInfo?: UserInfo
  handleComplete: () => void
  handleClose: () => void
  isEditVerification?: boolean
}

export interface UserInfo {
  email: string
  phoneNumber: string
}

export const VerificationModal: FC<VerificationModalProps> = ({
  isEmail,
  handleClose,
  handleComplete,
  userInfo: existingUserInfo,
  isEditVerification,
}) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(existingUserInfo)
  const [updateConsumerProfile] = useUpdateConsumerProfileMutation()
  const [isLoading, setIsLoading] = useState(false)

  const consumerProfile = useSelector(selectors.getConsumerProfile)

  const handleSubmit = (submittedInfo: UserInfo): void => {
    setUserInfo(submittedInfo)
  }

  const handleDismiss = (): void => {
    handleClose()
    // Set timeout to prevent modal from returning to the form state before close
    setTimeout(() => setIsLoading(false), 5000)
  }

  const handlePopaSuccess = async (): Promise<void> => {
    if (!userInfo) {
      return
    }

    const requestUserInfo = { ...userInfo }
    setIsLoading(true)
    setUserInfo(undefined)
    if (consumerProfile) {
      const body = userInfo.email
        ? { emails: { [requestUserInfo.email]: { isPrimary: true, isVerified: true } } }
        : {
            phones: {
              [formatPhoneNumberIso(requestUserInfo.phoneNumber)]: {
                isPrimary: true,
                isVerified: true,
              },
            },
          }

      try {
        const updatedProfile = await updateConsumerProfile({
          id: consumerProfile.profile.id,
          body,
        }).unwrap()
        dispatch(setConsumerProfile(updatedProfile))
        toast({
          message: 'Contact infomation updated.',
          toastColor: ToastColor.green,
          toastDuration: ToastDuration.short,
        })
      } catch (err: unknown) {
        toast({
          message: 'An error occurred.',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      }
      handleComplete()
      // Set timeout to prevent modal from returning to the form state before close
      setTimeout(() => setIsLoading(false), 5000)
    }
  }

  const handleReset = (): void => {
    setUserInfo(undefined)
    if (isEditVerification) {
      handleClose()
    }
  }

  if (isLoading) {
    return (
      <Modal heading="" size="sm" onDismissRequest={handleDismiss}>
        <Loading />
      </Modal>
    )
  }

  return (
    <Modal heading="" size="sm" onDismissRequest={handleDismiss} isMobile={isMobile} data-cy="verification-modal">
      <Container>
        {userInfo ? (
          <PopaFlow userInfo={userInfo} isAuth={false} resetForm={handleReset} handleSuccess={handlePopaSuccess} />
        ) : (
          <MyExtendAuthForm onSubmit={handleSubmit} isVerification isRestrictedEmail={isEmail} />
        )}
      </Container>
    </Modal>
  )
}

const Container = styled.div({
  height: 'auto',
  overflow: 'hidden',
})
