import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp, COLOR, Grid, FormattedMessage } from '@customers-ui'

import { images } from '../../lib/assets'

const ContactFormHeader: FC = () => {
  return (
    <>
      <Title>
        <FormattedMessage
          id="SUPPORT_FORM_TITLE"
          defaultMessage="Contact Us"
          description="This is the title for the support form"
        />
      </Title>
      <Grid>
        <Header>
          <FormattedMessage
            id="SUPPORT_FORM_INTRO_TITLE"
            defaultMessage="Hey there!"
            description="This is a greeting for the user"
          />
        </Header>
        <HandWave src={images.handWave} alt="hand-wave" />
      </Grid>
      <Content>
        <FormattedMessage
          id="SUPPORT_FORM_INTRO_MESSAGE"
          defaultMessage="Questions on your protection plans – we are here to help! Please fill out the form or if you would like to speak with someone you can call (877) 248-7707."
          description="This is an introductory message about the support form and also provides a support phone number"
        />
      </Content>
    </>
  )
}

const Title = styled.div({
  color: COLOR.BLACK,
  textAlign: 'center',
  fontWeight: 500,
  [bp.mobile]: {
    marginBottom: 16,
    fontSize: 24,
    lineHeight: '28px',
  },
  [bp.desktop]: {
    marginBottom: 24,
    fontSize: 36,
    lineHeight: '44px',
  },
})

const Header = styled.header({
  color: COLOR.BLACK,
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: 17,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '28px',
  },
})

const Content = styled.div({
  color: COLOR.NEUTRAL[800],
  [bp.mobile]: {
    margin: '8px 0 16px',
    fontSize: 15,
    lineHeight: '20px',
  },
  [bp.desktop]: {
    margin: '16px 0 40px',
    fontSize: 16,
    lineHeight: '22px',
  },
})

const HandWave = styled.img({
  alignSelf: 'center',
  width: 18,
  height: 18,
  [bp.mobile]: {
    marginLeft: 4,
  },
  [bp.desktop]: {
    marginLeft: 12,
  },
})

export { ContactFormHeader }
