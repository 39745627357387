import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  AuthFinishRequest,
  AuthFinishResponse,
  AuthStartRequest,
  AuthStartResponse,
  ConsumerProfileResponse,
  ConsumerProfileUpdateRequest,
  MyExtendApiVersion,
  MyExtendRequestWithApiVersion,
  OrdersListRequest,
  OrdersListResponse,
  LoginRequest,
} from './types'
import { X_EXTEND_ACCESS_TOKEN, baseQuery } from '../base-query'
import type { ConsumerProfilePatchRequest, TrackingRequest, TrackingResponse } from '@extend-myextend/types'

export const myExtendApi = createApi({
  reducerPath: 'MyExtend',
  baseQuery,
  tagTypes: ['myextend'],
  endpoints: (build) => ({
    finishPopaChallenge: build.mutation<AuthFinishResponse, MyExtendRequestWithApiVersion<AuthFinishRequest>>({
      query: ({ body, apiVersion = 'latest' }) => ({
        url: '/myextend/auth/finish',
        method: 'POST',
        body,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      invalidatesTags: ['myextend'],
    }),
    startPopaChallenge: build.mutation<AuthStartResponse, MyExtendRequestWithApiVersion<AuthStartRequest>>({
      query: ({ body, apiVersion = 'latest' }) => ({
        url: '/myextend/auth/start',
        method: 'POST',
        body,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      invalidatesTags: ['myextend'],
    }),
    searchConsumerProfile: build.query<ConsumerProfileResponse, MyExtendApiVersion>({
      query: ({ apiVersion = 'latest', accessToken }) => ({
        url: '/myextend/profiles',
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
          [X_EXTEND_ACCESS_TOKEN]: accessToken,
        },
      }),
    }),
    updateConsumerProfile: build.mutation<ConsumerProfileResponse, ConsumerProfileUpdateRequest>({
      query: ({ apiVersion = 'latest', body, id }) => ({
        url: `/myextend/profiles/${id}`,
        method: 'PUT',
        body,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
    }),
    patchConsumerProfile: build.mutation<ConsumerProfileResponse, MyExtendRequestWithApiVersion<ConsumerProfilePatchRequest>>({
      query: ({ body, apiVersion = '2022-02-01', id }) => ({
        url: `/myextend/profiles/${id}`,
        method: 'PATCH',
        body,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
    }),
    tracking: build.query<TrackingResponse, TrackingRequest & Omit<MyExtendApiVersion, 'accessToken'>>({
      query: ({ trackingNumber, carrierCode, orderId, transactionId, apiVersion = '2022-02-01' }) => ({
        url: '/myextend/tracking',
        method: 'GET',
        params: { trackingNumber, carrierCode, orderId, transactionId },
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
    }),
    listOrders: build.query<OrdersListResponse, OrdersListRequest>({
      query: ({ apiVersion = '2022-02-01', viewBy }) => ({
        url: '/myextend/orders',
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
        ...(viewBy && { params: { viewBy } }),
      }),
    }),
    login: build.mutation<ConsumerProfileResponse, MyExtendRequestWithApiVersion<LoginRequest>>({
      query: ({ body, apiVersion = '2022-02-01', accessToken }) => ({
        url: '/myextend/login',
        method: 'POST',
        body,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
          [X_EXTEND_ACCESS_TOKEN]: accessToken,
        },
      }),
    }),
  }),
})

export const {
  useFinishPopaChallengeMutation,
  useStartPopaChallengeMutation,
  useLazySearchConsumerProfileQuery,
  useUpdateConsumerProfileMutation,
  usePatchConsumerProfileMutation,
  useTrackingQuery,
  useListOrdersQuery,
  useLoginMutation,
} = myExtendApi
