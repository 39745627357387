import { useCallback, useState } from 'react'
import { useLoginMutation } from '@customers-api-rtk-query'
import { useSelector } from 'react-redux'
import type { ConsumerProfileResponse, LoginRequest } from '@customers-api-rtk-query'
import { isCodeInError, isFetchBaseQueryError } from '../lib/helper-functions'
import * as selectors from '@src/reducers/selectors'
import type { RootState } from '@src/reducers'

export const useMyExtendLogin = () => {
  const [isErrorPageVisible, setIsErrorPageVisible] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [profile, setProfile] = useState<ConsumerProfileResponse | null>(null)
  const emailToken = useSelector((state: RootState) => selectors.getEmailToken(state))
  const phoneToken = useSelector((state: RootState) => selectors.getPhoneToken(state))

  const [login, { isLoading, error, isError }] = useLoginMutation()

  const requestLogin = useCallback(async (): Promise<{
    isFirstTimeUser: boolean
    profile: ConsumerProfileResponse | null
  }> => {
    setIsErrorPageVisible(false)
    setIsSuccess(false)

    const requestBody: LoginRequest = {} as NonNullable<LoginRequest>
    if (emailToken && phoneToken) {
      requestBody.emailToken = emailToken
      requestBody.phoneToken = phoneToken
    }

    try {
      const response = await login({
        body: requestBody,
        accessToken: emailToken || phoneToken || '',
      }).unwrap()

      setProfile(response)
      setIsSuccess(true)
      return { isFirstTimeUser: false, profile: response }
    } catch (error) {
      if (isFetchBaseQueryError(error) && isCodeInError(error.data)) {
        const errorCode = error.data.code
        if (errorCode === 'profile_not_found') {
          return { isFirstTimeUser: true, profile: null }
        }
        setIsErrorPageVisible(true)
      }
      throw error
    }
  }, [login, emailToken, phoneToken])

  return {
    isError,
    isErrorPageVisible,
    isSuccess,
    isLoading,
    error,
    profile,
    requestLogin,
  }
}
