import React from 'react'
import { Card } from '@src/components/card'
import { COLOR, Icon, Lock } from '@extend/zen'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'

interface ProgressCardProps {
  progress: number
  children: React.ReactNode
}

export const ProgressCard = ({ progress = 0, children }: ProgressCardProps) => {
  return (
    <Wrapper>
      <Card
        data-cy="progress-card"
        heading={
          <HeaderWrapper data-testid="header-wrapper">
            <Icon icon={Lock} color={COLOR.NEUTRAL[700]} />
            <ProgressBarWrapper>
              <Progress width={progress} data-testid="progress-bar" />
            </ProgressBarWrapper>
          </HeaderWrapper>
        }
        headerBackground={COLOR.NEUTRAL[100]}
        hasBodyPadding={isMobile}
      >
        {children}
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const HeaderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  paddingBottom: '4px',
})

const ProgressBarWrapper = styled.div({
  position: 'absolute',
  bottom: '-25px',
  left: '-25px',
  right: '-25px',
  height: '5px',
  marginTop: '-1px',
})

const Progress = styled.div<{ width: number }>(({ width }) => ({
  height: '100%',
  width: '100%',
  background: `linear-gradient(to right, ${COLOR.NEUTRAL[500]} ${width}%, ${COLOR.NEUTRAL[300]} ${width}%)`,
  transition: 'width 0.3s ease-in-out',
  marginTop: '-1px',
}))
