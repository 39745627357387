import React, { useState, useEffect } from 'react'
import type { FC } from 'react'
import { isMobile } from 'react-device-detect'
import type { ModalButtonProps } from '@extend/zen'
import { Info, InlineAlert, InlineAlertColor, Modal, ModalController } from '@extend/zen'
import styled from '@emotion/styled'
import type { Claim, PhotoRequirement } from '../../types/claim'
import { logEvent } from '../../analytics'
import { PhotoInstruction } from './photo-instruction'
import { usePhotoRequirements, usePhotoRequirementsUpload } from './hooks'
import { ErrorToast } from '../common/error-toast'
import { usePhotoPolling } from '@src/hooks'
import { getInitialPhotoRequirementsState } from '@src/lib/photo-requirements-utils'

interface PhotoUploadModalProps {
  claim: Claim
  onDismiss: () => void
  isModalOpen: boolean
  handleResultModalOpen: () => void
}

const PhotoUploadModal: FC<PhotoUploadModalProps> = ({ claim, onDismiss, isModalOpen, handleResultModalOpen }) => {
  const [shouldFetchPhotos, setShouldFetchPhotos] = useState(false)
  const [isUploadingPhotos, setIsUploadingPhotos] = useState<boolean>(false)
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false)
  const [photoRequirements, setPhotoRequirements] = useState<PhotoRequirement[]>([])
  const [uploadPhotoIds, setUploadPhotoIds] = useState<string[]>([])

  const { claimPhotosData } = usePhotoPolling({
    claimId: claim.id,
    shouldFetchPhotos: shouldFetchPhotos && isModalOpen,
    pollingInterval: 1000,
    filterStatuses: ['saved', 'rejected'],
  })

  const {
    currentRequirement,
    setCurrentRequirement,
    moveToNextPhoto,
    clearRejectionForRequirement,
    rejectedPhotoRequirementIds,
  } = usePhotoRequirements({
    isModalOpen,
    claimPhotosData,
    photoRequirements,
    uploadPhotoIds,
    onRequirementsMet: () => {
      onDismiss()
      handleResultModalOpen()
    },
    onRequirementsUploaded: () => {
      setShouldFetchPhotos(true)
    },
  })

  const {
    photoId,
    currentImage,
    setCurrentImage,
    handleUpload,
    isLoading: isPhotoUploading,
  } = usePhotoRequirementsUpload({
    claimId: claim.id,
    currentRequirement: currentRequirement?.requirement ?? null,
    onError: () => setIsErrorToastVisible(true),
  })

  const isLoading = isPhotoUploading || shouldFetchPhotos

  /**
   * Reset modal state when closed
   * Set initial requirements when claim changes
   */
  useEffect(() => {
    if (!isModalOpen) {
      setShouldFetchPhotos(false)
      setCurrentRequirement(null)
      setCurrentImage(undefined)
      setUploadPhotoIds([])
      setPhotoRequirements([])
      return
    }

    /**
     * The initial render uses the claim from listClaims
     * In order to get the photo requirement sample photos,
     * we need to set the photo requirements when the claim changes
     */
    if (claim?.photoRequirements?.length) {
      setPhotoRequirements(claim.photoRequirements)
    }
  }, [isModalOpen, claim])

  /**
   * Photo Requirement Navigation
   * when a photo is uploaded, we need to add it to the upload photo ids
   * and then move to the next photo requirement
   */
  useEffect(() => {
    if (!photoId) return

    setUploadPhotoIds((prev) => [...prev, photoId])

    moveToNextPhoto()
  }, [photoId])

  /**
   * Initial render polling state management
   * Once initial photos are fetched, stop polling
   */
  useEffect(() => {
    if (!isModalOpen) {
      setShouldFetchPhotos(false)
      return
    }

    // Start polling when modal opens to fetch any existing photos
    if (!shouldFetchPhotos && !photoRequirements.length && !uploadPhotoIds.length) {
      setShouldFetchPhotos(true)
      return
    }

    // Once initial photos are fetched, stop polling
    if (shouldFetchPhotos && photoRequirements.length && !uploadPhotoIds.length && claimPhotosData !== null) {
      setShouldFetchPhotos(false)
    }
  }, [isModalOpen, photoRequirements, shouldFetchPhotos, uploadPhotoIds, claimPhotosData])

  /**
   * Post-Requirements Uploading State Management
   */
  useEffect(() => {
    if (!rejectedPhotoRequirementIds.length) return

    const hasAllUploadPhotoIdsProcessed = uploadPhotoIds.every((id) =>
      claimPhotosData?.find((photo) => photo.id === id),
    )
    if (!hasAllUploadPhotoIdsProcessed) return

    if (shouldFetchPhotos && rejectedPhotoRequirementIds?.length) {
      setShouldFetchPhotos(false)
      setCurrentRequirement(getInitialPhotoRequirementsState(photoRequirements, claimPhotosData ?? []))
    }
  }, [claimPhotosData, photoRequirements, rejectedPhotoRequirementIds, shouldFetchPhotos, uploadPhotoIds])

  const handleImageChange = (photo: File): void => {
    if (currentRequirement?.requirement.id) {
      clearRejectionForRequirement(currentRequirement.requirement.id)
    }
    setCurrentImage(photo)
  }

  const handleSubmit = () => {
    if (!currentRequirement?.isLastRequirement) {
      return
    }
    logEvent('Photo Upload Submit Claim - Click', 'Submit')
    handleUpload()
  }

  const initialButtonProps: { [key: string]: ModalButtonProps } = {
    primary: {
      text: 'Continue',
      onClick: () => setIsUploadingPhotos(true),
      emphasis: 'high',
      'data-cy': 'photo-instructions-modal-continue-button',
      isProcessing: isLoading,
    },
    secondary: {
      text: 'Exit',
      onClick: onDismiss,
      emphasis: 'medium',
      'data-cy': 'photo-instructions-modal-exit-button',
    },
  }

  const uploadingButtonProps: { [key: string]: ModalButtonProps } = {
    primary: {
      text: currentRequirement?.isLastRequirement ? 'Submit' : 'Save and continue',
      onClick: currentRequirement?.isLastRequirement ? handleSubmit : handleUpload,
      emphasis: 'high',
      'data-cy': 'photo-upload-save-continue-button',
      isDisabled: isPhotoUploading || !currentImage,
      isProcessing: isLoading,
    },
    secondary: {
      text: 'Exit',
      onClick: onDismiss,
      emphasis: 'medium',
      'data-cy': 'photo-upload-exit-button',
    },
  }

  // because we may have to re-upload a photo for a requirement
  // we need to find the index of the current requirement in the claim
  const claimRequirementIndex = claim?.photoRequirements?.findIndex(
    (requirement) => requirement.id === currentRequirement?.requirement?.id,
  )
  return (
    <ModalController isOpen={isModalOpen}>
      <Modal
        heading={isUploadingPhotos ? 'Upload Photos' : 'Photo Instructions'}
        size="sm"
        onDismissRequest={onDismiss}
        primaryButtonProps={isUploadingPhotos ? uploadingButtonProps.primary : initialButtonProps.primary}
        secondaryButtonProps={isUploadingPhotos ? uploadingButtonProps?.secondary : initialButtonProps.secondary}
        isMobile={isMobile}
        maxHeight={!isMobile ? '80vh' : undefined}
        data-cy="photo-upload-modal"
        hasBodyPadding
      >
        <ContentWrapper data-cy="photo-upload-content-wrapper">
          <ErrorToast isVisible={isErrorToastVisible} />
          {!isUploadingPhotos &&
            photoRequirements?.map((item, index) => {
              return <PhotoInstruction key={item.id} photoRequirement={item} index={index} />
            })}
          {isUploadingPhotos && currentRequirement && !isLoading && (
            <PhotoInstruction
              photoRequirement={currentRequirement?.requirement}
              index={claimRequirementIndex ?? currentRequirement?.index}
              onChange={handleImageChange}
              isUploadingPhotos={isUploadingPhotos}
              isPhotoRejected={rejectedPhotoRequirementIds.includes(currentRequirement?.requirement.id ?? '')}
            />
          )}
          <InlineAlert data-cy="attachments-inline-alert" color={InlineAlertColor.yellow} icon={Info}>
            Please do not submit photos that depict people or other personally-identifying info
          </InlineAlert>
        </ContentWrapper>
      </Modal>
    </ModalController>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
})

export { PhotoUploadModal }
