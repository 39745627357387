export { usePhotoRequirementsUpload } from './use-photo-requirements-upload'
export { usePhotoRequirements } from './use-photo-requirements'
/**
 * Photo Upload Flow Hook Architecture
 * 
 * Component Hierarchy:
 * PhotoUploadModal
 * ├─ usePhotoRequirements      // Main orchestrator
 * ├─ usePhotoRequirementsUpload // Handles file uploads
 * └─ usePhotoPolling // Manages API polling
 * 
 * Data Flow:
 * 1. Modal initializes with photo requirements from claim
 * 2. usePhotoRequirements manages the current requirement state
 * 3. When photo uploaded:
 *    - usePhotoRequirementsUpload creates photo record and uploads file
 *    - Returns photo ID and current image for tracking
 * 4. On last requirement:
 *    - usePhotoRequirements indicates polling should start
 *    - usePhotoPolling begins checking photo statuses
 * 5. When all photos processed:
 *    - If any rejections, usePhotoRequirements updates rejection state
 *    - If all saved, modal closes and shows results
 * 
 * State Management:
 * - Modal owns main state (shouldFetchPhotos, uploadPhotoIds)
 * - usePhotoRequirements manages current requirement and rejection states
 * - usePhotoRequirementsUpload handles upload flow and returns results
 * - usePhotoPolling handles API polling state
 * 
 * Key Interactions:
 * - Upload Success → Move to Next → Start Polling (if last)
 * - Polling → Photo Rejected → Track Rejections
 * - Polling → All Saved → Show Results
 */