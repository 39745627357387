import { useCallback, useState } from 'react'
import { useStartPopaMutation, useFinishPopaMutation } from '@customers-api-rtk-query'
import type { StartPopaRequest } from '@customers-api-rtk-query'
import { useTheme } from '@emotion/react'
import type { UserInfo } from '../pages/myextend-authentication/myextend-authentication'
import { buildAuthPayload } from '../pages/myextend-authentication/utils'
import { getItem, ACCESS_TOKEN_KEY } from '../store/persistence'
import { isCodeInError, isFetchBaseQueryError } from '../lib/helper-functions'
import type { Locale } from '../types/localization'

export const TOAST_ERRORS: Record<string, string> = {
  invalid_challenge_response: 'Incorrect passcode entered. Please try again.',
  max_number_of_challenge_response_attempts: 'Exceeded maximum login attempts. Please request a new passcode.',
  challenge_expired: 'Your passcode has expired. Please request a new one.',
}

const RETURN_TO_USER_INFO_ERRORS = ['max_number_of_challenge_response_attempts', 'challenge_expired']

export const useAuthPopaManagement = (
  userInfo: UserInfo,
): {
  sessionId: string
  accessToken: string | null
  errorType: string | null
  isErrorPageVisible: boolean
  isToastError: boolean
  isReturnToUserInfoError: boolean
  isTokenLoading: boolean
  isSuccess: boolean
  requestPopaSessionId: (locale: Locale) => void
  requestPopaToken: (sessionId: string, passcode: string, locale: Locale) => void
} => {
  const [popaSessionId, setPopaSessionId] = useState<string>('')
  const [accessToken, setAccessToken] = useState<string | null>(getItem(ACCESS_TOKEN_KEY) as string | null)
  const [getAuthSessionId] = useStartPopaMutation()
  const [getAuthPopaToken, { isLoading: isAuthLoading }] = useFinishPopaMutation()
  const [errorType, setErrorType] = useState<string | null>(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isToastError, setIsToastError] = useState<boolean>(false)
  const [isReturnToUserInfoError, setIsReturnToUserInfoError] = useState<boolean>(false)
  const [isErrorPageVisible, setIsErrorPageVisible] = useState<boolean>(false)
  const theme = useTheme()
  const { isExtend, subdomainName } = theme.merchantConfiguration
  const isLoading = isAuthLoading

  const mapAuthError = async (error: unknown): Promise<void> => {
    if (isFetchBaseQueryError(error) && isCodeInError(error.data)) {
      const errorCode = error.data.code
      if (errorCode in TOAST_ERRORS) {
        setIsToastError(true)
        setErrorType(TOAST_ERRORS[errorCode])
        if (RETURN_TO_USER_INFO_ERRORS.includes(errorCode)) {
          setIsReturnToUserInfoError(true)
        }
      } else if (errorCode === 'max_number_of_challenge_requests') {
        setErrorType(errorCode)
      } else {
        setErrorType('authentication_failed')
      }
    } else {
      setErrorType('authentication_failed')
    }
  }

  const requestPopaSessionId = useCallback(
    async (locale: Locale) => {
      if (userInfo.email || userInfo.phoneNumber) {
        try {
          const payload = buildAuthPayload(userInfo, locale, isExtend, subdomainName) as StartPopaRequest
          const response = await getAuthSessionId(payload).unwrap()
          if (response && response.session) {
            setPopaSessionId(response.session)
            setIsSuccess(true)
          }
        } catch (error) {
          mapAuthError(error)
          setIsErrorPageVisible(true)
        }
      }
    },
    [getAuthSessionId, userInfo],
  )

  const requestPopaToken = useCallback(
    async (sessionId: string, passcode: string, locale: Locale): Promise<void> => {
      setIsReturnToUserInfoError(false)
      setIsToastError(false)
      setErrorType(null)

      try {
        const payload = buildAuthPayload(userInfo, locale, isExtend, subdomainName)
        const response = await getAuthPopaToken({
          ...payload,
          session: sessionId,
          response: passcode,
        }).unwrap()
        if (response.access_token) {
          setAccessToken(response.access_token)
          setIsSuccess(true)
        }
      } catch (error) {
        mapAuthError(error)
      }
    },
    [userInfo, getAuthPopaToken],
  )

  return {
    errorType,
    accessToken,
    sessionId: popaSessionId,
    isErrorPageVisible,
    isToastError,
    isReturnToUserInfoError,
    isTokenLoading: isLoading,
    isSuccess,
    requestPopaSessionId,
    requestPopaToken,
  }
}
