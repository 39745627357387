import React, { useRef, useState, useEffect } from 'react'
import type { ChangeEvent, FC } from 'react'
import styled from '@emotion/styled'
import { COLOR, GenericField, Input } from '@extend/zen'
import { getDetails, getFullAddress, getPlacePredictions, initServices } from '../../lib/google-api'
import type { ConsumerProfileResponse } from '@customers-api-rtk-query'

interface AddressInputProps {
  onAddressChange: (address: ConsumerProfileResponse['addresses'][0]) => void
}

const AddressInput: FC<AddressInputProps> = ({ onAddressChange }) => {
  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState<Array<{ label: string; value: string }>>([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [unitNumberInput, setUnitNumberInput] = useState('')
  const [isMapsServicesLoaded, setIsMapsServicesLoaded] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const initGoogleApi = async (): Promise<void> => {
      await initServices()
      setIsMapsServicesLoaded(true)
    }
    initGoogleApi()
  }, [])

  const handleInputChange = (value: string) => {
    setInputValue(value)
    // Clear suggestions if input is empty
    if (!value.trim()) {
      setSuggestions([])
      return
    }
    if (!isMapsServicesLoaded) return
    getPlacePredictions({ input: value, types: ['address'] }, (predictions) => {
      if (!predictions?.length) {
        setSuggestions([])
        return
      }
      setSuggestions(
        predictions.map((p) => ({
          label: p.description,
          value: p.place_id,
        })),
      )
    })
  }

  const handleSuggestionSelect = (suggestion: { label: string; value: string }) => {
    setInputValue(suggestion.label)
    setShowSuggestions(false)
    getDetails({ placeId: suggestion.value, fields: ['address_component'] }, (result) => {
      if (!result?.address_components?.length) return
      const address = getFullAddress(result.address_components)
      onAddressChange({ ...address, address2: unitNumberInput || ' ' })
    })
  }

  const handleUnitNumberInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    setUnitNumberInput(value)
    if (inputValue) {
      getDetails({ placeId: suggestions[0]?.value, fields: ['address_component'] }, (result) => {
        if (!result?.address_components?.length) return
        const address = getFullAddress(result.address_components)
        onAddressChange({ ...address, address2: value || ' ' })
      })
    }
  }

  return (
    <AddressForm data-cy="address-form">
      <GenericField label="Shipping Address" data-cy="address-select-field">
        <GoogleMapsInputWrapper ref={wrapperRef}>
          <Input
            id="address-input"
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value)}
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => {
              // give it time for the user to select an option before closing the suggestions list
              setTimeout(() => setShowSuggestions(false), 200)
            }}
            data-cy="address-select-input"
          />
          {showSuggestions && suggestions.length > 0 && (
            <SuggestionsList data-cy="address-suggestions-list">
              {suggestions.map((suggestion, index) => (
                <SuggestionItem 
                  key={suggestion.value}
                  data-cy={`address-suggestion-${index}`}
                  onClick={() => handleSuggestionSelect(suggestion)}
                >
                  {suggestion.label}
                </SuggestionItem>
              ))}
            </SuggestionsList>
          )}
        </GoogleMapsInputWrapper>
      </GenericField>
      <Input
        id="apt-unit-input"
        value={unitNumberInput}
        label="Apt, suite, etc. (Optional)"
        onChange={handleUnitNumberInputChange}
        data-cy="apt-unit-input"
      />
    </AddressForm>
  )
}

const AddressForm = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flexStart',
  gap: '16px',
  alignSelf: 'stretch',
})

const SuggestionsList = styled.ul({
  position: 'absolute',
  bottom: '100%',
  left: 0,
  right: 0,
  backgroundColor: 'white',
  border: `1px solid ${COLOR.NEUTRAL[400]}`,
  borderRadius: 8,
  marginBottom: 4,
  padding: 0,
  listStyle: 'none',
  zIndex: 1000,
  maxHeight: 200,
  overflowY: 'auto',
})

const SuggestionItem = styled.li({
  padding: 8,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: COLOR.BLUE[100],
    color: COLOR.BLUE[700],
  },
})

const GoogleMapsInputWrapper = styled.div({
  width: '100%',
  position: 'relative',
})

export { AddressInput }
