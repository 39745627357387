import { useState, useCallback, useEffect } from 'react'
import type { PhotoRequirement, ClaimPhotoDetail } from '../../../types/claim'
import type { CurrentRequirementState } from '../../../lib/photo-requirements-utils'
import { getInitialPhotoRequirementsState } from '../../../lib/photo-requirements-utils'

interface UsePhotoRequirementsProps {
  isModalOpen: boolean
  claimPhotosData: ClaimPhotoDetail[] | null
  photoRequirements: PhotoRequirement[] | undefined
  uploadPhotoIds: string[]
  onRequirementsMet: () => void
  onRequirementsUploaded: () => void
}

/**
 * Hook to manage photo requirements state and flow
 * Handles:
 * - Initial requirement setup
 * - Requirement navigation
 * - Polling for photo statuses
 * - Rejection handling
 */
export function usePhotoRequirements({
  isModalOpen,
  claimPhotosData,
  photoRequirements,
  uploadPhotoIds,
  onRequirementsMet,
  onRequirementsUploaded,
}: UsePhotoRequirementsProps) {
  const [currentRequirement, setCurrentRequirement] = useState<CurrentRequirementState | null>(null)
  const [rejectedPhotoRequirementIds, setRejectedPhotoRequirementIds] = useState<string[]>([])

  // Initialize current requirement when modal opens or requirements change
  useEffect(() => {
    if (!isModalOpen || !photoRequirements?.length || claimPhotosData === null) return
    setCurrentRequirement(getInitialPhotoRequirementsState(photoRequirements, claimPhotosData))
  }, [isModalOpen, photoRequirements, claimPhotosData])

  /**
   * Handle navigation between photo requirements
   * Starts polling when last requirement is reached
   * Skips requirements that already have saved photos
   */
  const moveToNextPhoto = useCallback(() => {
    if (!currentRequirement || !photoRequirements?.length) return

    if (currentRequirement.isLastRequirement) {
      setCurrentRequirement(null)
      onRequirementsUploaded()
      return
    }

    // Find next unsaved requirement
    const nextRequirement = photoRequirements
      .slice(currentRequirement.index + 1)
      .find(
        (requirement) =>
          !claimPhotosData?.some((photo) => photo.requirement?.id === requirement.id && photo.status === 'saved'),
      )

    if (!nextRequirement) {
      setCurrentRequirement(null)
      onRequirementsUploaded()
      return
    }

    // Just set the next requirement directly instead of using getNextPhotoRequirement
    setCurrentRequirement({
      requirement: nextRequirement,
      index: photoRequirements.indexOf(nextRequirement),
      isLastRequirement: nextRequirement === photoRequirements[photoRequirements.length - 1],
    })
  }, [currentRequirement, photoRequirements, claimPhotosData])

  // Handle photo rejections and update rejection state
  useEffect(() => {
    if (!claimPhotosData?.length || !uploadPhotoIds.length) return

    const hasAllUploadPhotoIdsProcessed = uploadPhotoIds.every((id) =>
      claimPhotosData?.find((photo) => photo.id === id),
    )
    if (!hasAllUploadPhotoIdsProcessed) return

    // Find photos that are rejected and don't have a saved version
    const rejectedPhotos = claimPhotosData.filter(
      (photo) =>
        photo.status === 'rejected' &&
        uploadPhotoIds.includes(photo.id) &&
        !claimPhotosData.some((p) => p.requirement?.id === photo.requirement?.id && p.status === 'saved'),
    )

    // If no rejections, notify completion
    if (!rejectedPhotos.length) {
      onRequirementsMet()
      return
    }

    // Update rejection state with new rejected requirement IDs
    const rejectedIds = rejectedPhotos.map((photo) => photo.requirement?.id ?? '')
    setRejectedPhotoRequirementIds((prev) => {
      // Only update if we have new rejections
      const newIds = rejectedIds.filter((id) => !prev.includes(id))
      return newIds.length ? [...prev, ...newIds] : prev
    })
  }, [claimPhotosData, uploadPhotoIds, onRequirementsUploaded, onRequirementsMet])

  /**
   * Clear rejection state for a specific requirement
   * Used when retrying a rejected photo
   */
  const clearRejectionForRequirement = useCallback((requirementId: string) => {
    setRejectedPhotoRequirementIds((prev) => prev.filter((id) => id !== requirementId))
  }, [])

  return {
    currentRequirement,
    setCurrentRequirement,
    moveToNextPhoto,
    rejectedPhotoRequirementIds,
    clearRejectionForRequirement,
  }
}
