import type { FC } from 'react'
import React, { useEffect, useState, useCallback } from 'react'
import { bp } from '@customers-ui'
import styled from '@emotion/styled'
import { useStartPopaChallengeMutation } from '@customers-api-rtk-query'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useTheme } from '@emotion/react'
import type { AuthStartRequest } from '@customers-api-rtk-query'
import { buildPayload } from '../../pages/myextend-authentication/utils'
import { setAccessToken } from '../../store/slices/my-extend'
import type { Locale } from '../../types/localization'
import { MyExtendAuthPasscodeInput } from '../../pages/myextend-authentication/myextend-auth-passcode-input'
import { isFetchBaseQueryError, isCodeInError } from '../../lib/helper-functions'
import { MyExtendAuthErrors } from '../../pages/myextend-authentication/myextend-auth-errors'
import { LDFlag } from '@src/constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAuthPopaManagement } from '@src/hooks'

interface PopaFlowProps {
  userInfo: { email: string; phoneNumber: string }
  isAuth?: boolean
  hasBorderRadius?: boolean
  resetForm: (error: string | null) => void
  handleSuccess: () => void
}

export const PopaFlow: FC<PopaFlowProps> = ({ userInfo, isAuth = true, resetForm, handleSuccess, hasBorderRadius = true }) => {
  const { [LDFlag.UseAuthPopa]: FF_USE_AUTH_POPA } = useFlags()
  const [sessionId, setSessionId] = useState<string>('')
  const [errorType, setErrorType] = useState<string>('')
  const [flowStarted, setFlowStarted] = useState(false)
  const [getSessionId] = useStartPopaChallengeMutation()
  const dispatch = useDispatch()
  const intl = useIntl()
  const [isErrorPageVisible, setIsErrorPageVisible] = useState<boolean>(false)
  const theme = useTheme()
  const { isExtend, subdomainName } = theme.merchantConfiguration

  const {
    sessionId: authSessionId,
    requestPopaSessionId,
    errorType: authErrorType,
    isErrorPageVisible: isAuthErrorPageVisible,
    isSuccess: isAuthSuccess,
  } = useAuthPopaManagement(userInfo)

  const requestSessionId = useCallback(async () => {
    if (isAuth) {
      dispatch(setAccessToken(''))
    }
    if (userInfo.email || userInfo.phoneNumber) {
      try {
        const payload = buildPayload(userInfo, intl.locale as Locale, isExtend, subdomainName) as AuthStartRequest
        const response = await getSessionId({ body: payload, apiVersion: 'latest' }).unwrap()
        if (response && response.sessionId) {
          setSessionId(response.sessionId)
        }
      } catch (error) {
        if (isFetchBaseQueryError(error) && isCodeInError(error.data)) {
          if (error.data.code === 'max_number_of_challenge_requests') {
            setErrorType(error.data.code)
          } else {
            setErrorType('authentication_failed')
          }
        } else {
          setErrorType('authentication_failed')
        }
        setIsErrorPageVisible(true)
      }
    }
  }, [dispatch, userInfo, intl.locale, getSessionId, isAuth])

  useEffect(() => {
    if (!flowStarted) {
      if (FF_USE_AUTH_POPA) {
        requestPopaSessionId(intl.locale as Locale)
      } else {
        requestSessionId()
      }
      setFlowStarted(true)
    }

    if (flowStarted && isAuthSuccess && FF_USE_AUTH_POPA && authSessionId) {
      setSessionId(authSessionId)
    }
  }, [
    flowStarted,
    requestSessionId,
    setFlowStarted,
    requestPopaSessionId,
    authSessionId,
    isAuthSuccess,
    FF_USE_AUTH_POPA,
  ])
  return (
    <>
      {isErrorPageVisible || isAuthErrorPageVisible ? (
        <MyExtendAuthErrors isAuth={isAuth} error={errorType || authErrorType} />
      ) : (
        <Wrapper isAuth={isAuth}>
          <Container>
            <MyExtendAuthPasscodeInput
              isAuth={isAuth}
              handleSuccess={handleSuccess}
              email={userInfo.email}
              phoneNumber={userInfo.phoneNumber}
              sessionId={sessionId}
              resetForm={resetForm}
              resendPasscode={requestSessionId}
              hasBorderRadius={hasBorderRadius}
            />
          </Container>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div<{ isAuth?: boolean; hasBorderRadius?: boolean }>(({ isAuth, hasBorderRadius }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  [bp.mobile]: {
    margin: 0,
    width: '100%',
  },
  [bp.desktop]: {
    ...(isAuth && { width: '100%' }),
    position: 'relative',
    height: `${isAuth ? '100vh' : '444px'}`,
    justifyContent: 'center',
    ...(hasBorderRadius && { borderRadius: '8px' }),
  },
}))

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  [bp.mobile]: {
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 0,
    gap: 50,
    width: '100%',
  },
  [bp.desktop]: {
    width: '100%',
    gap: 0,
    padding: 0,
    justifyContent: 'center',
    flexDirection: 'column',
  },
})
